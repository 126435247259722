import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import SeoForm from '@/components/seo-form/index.vue'
import { selectedModule } from '@/mixins'

export default {
  name: 'news-create',
  mixins: [validationMixin],
  data () {
    return {
      seoContent: '',
      id: this.$route.params.id,
      module: this.$route.name
    }
  },
  components: {
    SeoForm
  },
  computed: {
    ...mapGetters({
      currentItem: 'newsGeneral/newsGeneral',
      seo: 'newsSeo/seo',
      seoLoading: 'newsSeo/seoLoading',
      updateSeoLoading: 'newsSeo/seoUpdateLoading',
      accessCountries: 'profile/accessCountries',
      setting: 'courses/setting'
    })
  },
  created () {
    if (!this.seo) {
      this.fetchContent({
        id: this.$route.params.id,
        module: this.$route.name
      }).then(response => {
        this.seoContent = response
        this.setCountryIds([response.country_id])
      })
    }
    if (!this.setting) {
      this.fetchSetting()
    }
    if (!this.currentItem) {
      this.fetchData({ id: this.$route.params.id })
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'newsGeneral/GENERAL_GET_DATA',
      fetchContent: 'newsSeo/SEO_GET_DATA',
      fetchSetting: 'courses/COURSES_GET_SETTING',
      update: 'newsSeo/UPDATE',
      create: 'newsSeo/CREATE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS',
      setSeo: 'newsSeo/SEO_SET_DATA'
    }),
    submit (formData) {
      formData.seoable_type = selectedModule({ module: this.$route.name })
      formData.seoable_id = this.seoContent.id
      formData.module = this.$route.name

      const item = this.seo.find(e => e.locale === formData.locale)
      if (!item) {
        this.create(formData).then(() => {
          this.$toasted.success(this.$t('success_created'))
        })
      } else {
        formData._method = 'patch'
        this.update(formData).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
